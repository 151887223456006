<template>
  <div class="offer__item trial__reg">
    <img src="@main/assets/done_icon.png" />
    <div class="trial-reg_text">
      <p class="trial__reg-h">Спасибо, что прошли регистрацию!</p>
      <p class="trial__reg-h">ВП будет доступно в начале 2021 года!</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.trial__reg {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 200px !important;
  width: 370px !important;
  max-width: none;
}
.trial-reg_text {
  text-align: center;
}
.trial__reg-h {
  font-weight: 400;
  font-size: 16.5px;
  line-height: 24px;
}
</style>
