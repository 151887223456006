<template>
  <modal
    :show="show"
    @hide="$emit('hide')"
    title="Видео-инструкция по взаимопиару"
    wrapClass="popup-search-off__wrap"
    mainClass="popup-search-off"
    contentClass="popup-search-off__content"
  >
    <iframe
      height="315"
      src="https://www.youtube.com/embed/1AjKOa_wFq4"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

    <button
      class="link-blue center js-btnPopup"
      id="popupInstruction"
      @click="$emit('showInsruction')"
    >
      Инструкция текстом
    </button>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  props: {
    show: Boolean,
  },
  components: {
    Modal,
  },
};
</script>

<style scoped>
.footer {
  width: auto !important;
}
#popupInstruction {
  margin-top: 3em;
}
</style>
