<template>
  <div class="offer__item sale__item" v-if="time">
    <div class="sale__item-text">
      <h1 class="sale__header">{{ header }}</h1>
      <p class="sale__text">{{ text }}</p>
    </div>
    <div class="sale__time-wrapper">
      <div class="clock__block">
        <h1 class="clock__item">{{ time.hours }}</h1>
        <p class="clock__text">часа</p>
      </div>
      <div class="clock__block">
        <h1 class="clock__item">{{ time.minutes }}</h1>
        <p class="clock__text">минут</p>
      </div>
      <div class="clock__block">
        <h1 class="clock__item">{{ time.seconds }}</h1>
        <p class="clock__text">секунд</p>
      </div>
    </div>
    <div class="btn--orange sale__button" @click="() => $emit('action')">
      {{ buttonText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
    },
    text: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    endTime: {
      type: [String, Number, Object],
    },
  },
  data: () => ({
    time: null,
    timeInterval: null,
  }),
  methods: {
    getTimeDifference(endtime) {
      const diff = Date.parse(endtime) - Date.parse(new Date());
      const seconds = Math.floor((diff / 1000) % 60);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return {
        total: diff,
        days,
        hours,
        minutes,
        seconds,
      };
    },
    updateClock() {
      const time = this.getTimeDifference(this.endTime);

      this.time.total = time;
      this.time.days = time.days;
      this.time.hours = `0${time.hours}`.slice(-2);
      this.time.minutes = `0${time.minutes}`.slice(-2);
      this.time.seconds = `0${time.seconds}`.slice(-2);

      if (time.total <= 0) {
        clearInterval(this.timeInterval);
      }
    },
    initTime() {
      this.time = this.getTimeDifference(this.endTime);
    },
  },
  mounted() {
    this.initTime();
    this.timeInterval = setInterval(() => {
      this.updateClock();
    }, 1000);
    console.log(`endtime - ${this.endTime}`);
    console.log(`diff - ${this.time}`);
  },
};
</script>

<style lang="css" scoped>
.sale__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  float: none;
  width: none;
  width: unset;
  max-width: 800px;
  align-items: center;
  color: #192229;
}
.sale__item-text {
  max-width: 290px;
}
.sale__header {
  margin-bottom: 12px;
  font-weight: 500;
}
.sale__text {
  line-height: 16.59px;
  font-weight: 400;
  font-size: 14px;
  style: normal;
}
.sale__time-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 270px;
  align-items: center;
}
.clock__block {
  width: 76px;
  text-align: center;
  border: 1px solid #d5dfe4;
  border-radius: 5px;
  padding: 13px 1px;
  display: inline-table;
}
.clock__item {
  font-size: 20px;
  font-weight: 400;
}
.clock__text {
  margin-top: 8px;
  text-align: center;
  color: #6a7b83;
  font-size: 11px;
}
.sale__button {
  max-width: unset;
  height: 36px;
  width: 170px;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 816px) {
  .sale__item {
    flex-direction: column !important;
    min-height: 250px !important;
  }
  .sale__item-text {
    text-align: center !important;
  }
}
</style>
