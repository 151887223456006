<template>
  <modal
    :show="show"
    @hide="$emit('hide')"
    title="Инструкция текстом"
    wrapClass="popup-search-off__wrap"
  >
    <div class="popup-search-off__content">
      <ul class="popup-instruction__list">
        <li class="popup-instruction__item">Регистрируетесь на сервисе.</li>
        <li class="popup-instruction__item">Заполняете свою карточку и верифицируете аккаунт.</li>
        <li class="popup-instruction__item">Заходите в ВП на завтра.</li>
        <li class="popup-instruction__item">
          Выбираете партнера для ВП и отправляете ему запрос через наш сервис. Можно выбрать
          нескольких партнеров.
        </li>
        <li class="popup-instruction__item">
          Ожидаете ответ. Рекомендуем заходить несколько раз в день, чтобы не пропустить самые
          крутые предложения.
        </li>
        <li class="popup-instruction__item">
          Если ваш партнер по ВП согласился (это будет указано отдельно на странице), то жмете
          кнопку «Написать партнеру», копируете промокод и пишете ему в директ.
        </li>
        <li class="popup-instruction__item">
          Выкладываете рекламу партнера завтра, а он должен выложить вашу.
        </li>
        <li class="popup-instruction__item">Пишете отзыв о проведенном ВП.</li>
        <li class="popup-instruction__item">Повторяете процесс хоть каждый день.</li>
      </ul>
      <button @click="$emit('showAuth')" class="btn--orange">Войти или зарегистрироваться</button>
    </div>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  props: {
    show: Boolean,
  },
  components: {
    Modal,
  },
};
</script>

<style scoped>
.footer {
  width: auto !important;
}
#popupInstruction {
  margin-top: 3em;
}
.btn--orange {
  max-width: none;
  padding: 8.5px 10px;
}
</style>
