<script>
import { mapState } from 'vuex';
import Card from '@mp/components/Card/Card.vue';
import * as searchApi from '@mp/api/search';

export default {
  computed: {
    ...mapState(['currentUser']),
  },
  components: {
    Card,
  },
};
</script>
