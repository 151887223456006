<template>
  <modal
    :show="show"
    :title="title"
    @hide="() => $emit('hide')"
    mainClass="popup-card-disabled"
    wrapClass="popup-card-disabled__wrap"
    contentClass="popup-card-disabled__content popup__content"
  >
    <div class="card-wrapper">
      <Card :mode="cardMode" v-for="i in objs" :key="`${i.id}-${keyname}`" :account="i" />
    </div>
  </modal>
</template>

<script>
import Card from '@mp/components/Card/Card.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  props: {
    show: Boolean,
    title: String,
    cardMode: String,
    keyname: String,
    objs: Array,
  },
  components: {
    Card,
    Modal,
  },
};
</script>

<style lang="css" scoped></style>
