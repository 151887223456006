<template>
  <modal
    :show="show"
    @hide="$emit('hide')"
    title="Правила сервиса"
    wrapClass="popup-search-off__wrap"
    contentClass="popup-search-off__content "
  >
    <ul class="main__item-list">
      <li class="main__item-point">Дата ВП только завтрашняя.</li>
      <li class="main__item-point">Обмен рекламой в сторис 45 секунд.</li>
      <li class="main__item-point">Не более 2 ВП за день.</li>
      <li class="main__item-point">Партнеры для ВП подбираются системой.</li>
      <li class="main__item-point">Обман с охватами и статистикой карается баном на сайте.</li>
      <li class="main__item-point">
        При активации поиска до 17:00 по Москве система постарается подобрать вам до 10
        потенциальных партнеров, а после 17:00 — всего до 7. Поэтому мы рекомендуем активировать
        поиск утром.
      </li>
      <li class="main__item-point">
        Пользоваться сервисом может только блогер, у которого должно быть не меньше 1.000
        подписчиков, не паблик и не коммерческий аккаунт.
      </li>
    </ul>
    <button @click="$emit('showAuth')" class="btn--orange rules-btn">
      Войти или зарегистрироваться
    </button>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  props: {
    show: Boolean,
  },
  components: {
    Modal,
  },
};
</script>

<style scoped>
.footer {
  width: auto !important;
}
#popupInstruction {
  margin-top: 3em;
}
.btn--orange {
  max-width: none;
  padding: 8.5px 10px;
}
@media (max-width: 350px;) {
  .btn--orange {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
