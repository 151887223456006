<template>
  <Modal
    class="popup-search-off"
    :show="show"
    @hide="() => $emit('hide')"
    wrapClass="popup-search-off__wrap"
    title="Отключить поиск ВП"
    contentClass="popup-search-off__content"
  >
    <div class="">
      <p class="popup-search-off__text">
        Отключив поиск ВП, вы не сможете больше принимать входящие заявки сегодня.
      </p>
      <p class="popup-search-off__text">
        Уже найденные партнеры и отправленные ранее вами заявки не исчезнут.
      </p>
      <p class="popup-search-off__text">Активировать поиск снова можно будет после 00:00 по Мск.</p>
      <div class="popup-search-off__footer">
        <button
          type="button"
          class="btn--border popup-search-off__btn--cancel"
          @click="() => $emit('hide')"
        >
          Отмена
        </button>
        <b-button
          variant="outline-default"
          type="button"
          class="popup-search-off__btn--off"
          :disabled="disableButton"
          @click="() => $emit('searchOff')"
        >
          Отключить поиск ВП
          <span style="margin-bottom: 5px" v-if="disableButton"
            >Кнопка станет доступна через {{ second }} секунд</span
          >
        </b-button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  props: {
    show: Boolean,
  },
  components: {
    Modal,
  },
  watch: {
    show(newVal) {
      this.second = 5;
      if (newVal) {
        this.startCounter();
      } else {
        clearInterval(this.interval);
      }
    },
  },
  computed: {
    disableButton() {
      return this.second > 0;
    },
  },
  data() {
    return {
      second: 5,
      interval: null,
    };
  },
  methods: {
    startCounter() {
      // eslint-disable-next-line no-return-assign
      this.interval = setInterval(() => {
        this.second -= 1;
      }, 1000);
    },
  },
};
</script>

<style scoped>
@media (max-width: 360px) {
  .popup-search-off__footer {
    flex-direction: column;
    align-items: flex-end;
  }
  .btn--border {
    width: 100% !important;
    max-width: none !important;
    margin-top: 4px;
  }
}
</style>
