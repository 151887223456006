<template lang="html">
  <div class="pr-events--future" v-if="mutualPrs.length > 0">
    <div class="pr-events__title-wrap">
      <h2 class="pr-events__title">
        Назначено ВП на сегодня<span>{{ mutualPrs.length }}/2</span>
      </h2>
    </div>
    <div class="card-wrapper pr__card-wrap">
      <Card
        mode="accepted"
        v-for="(i, idx) in mutualPrs"
        :key="`${idx}-mpToday`"
        :account="i.customer.id === currentUser.id ? i.executor : i.customer"
        @cancel-mp="mpCancel($event)"
      />
    </div>
  </div>
</template>

<script>
import MutualPRMixin from '@main/mixins/mutualpr/MutualPRMixin';
import { cancelMp } from '@mp/api/search';

export default {
  props: {
    mutualPrs: Array,
  },
  mixins: [MutualPRMixin],
  methods: {
    async mpCancel(val) {
      await cancelMp(val);
    },
  },
};
</script>

<style lang="css" scoped></style>
