<template>
  <div>
    <Modal
      :show="show"
      @hide="hide"
      title="Поиск ВП у меня в сторис"
      wrapClass="popup-search-insta__wrap"
      contentClass="popup__content popup-search-insta__content"
      mainClass="popup popup-search-insta--expl"
    >
      <div class="popup-search-insta__item popup-search-insta__item--mrright">
        <p class="popup-search-insta__text">
          Скопируйте ссылку из формы ниже и сделайте у себя сторис по поиску партнеров для ВП,
          разместив ссылку по свайпу вверх.
        </p>
        <p class="popup-search-insta__text">
          Так пользователи сразу смогут посмотреть вашу карточку и откликнуться на неё.
        </p>
        <a :href="linkToPromo" class="popup-search-insta__link" download v-if="linkIsReady"
          >Скачать готовый макет</a
        >
        <span class="popup-search-insta__link" v-else>Подготовка ссылки...</span>
        <input type="url" class="input popup-search-insta__input" :value="link" readonly />
        <b-button
          variant="warning"
          class="btn--orange popup-search-insta__btn"
          v-clipboard:copy="link"
          v-clipboard:success="copyAndOpen"
        >
          Скопировать и перейти в аккаунт
        </b-button>
      </div>
      <div class="popup-search-insta__item">
        <span class="popup-search-insta__subtitle">Пример сторис</span>
        <div class="popup-search-insta__video">
          <iframe
            width="100%"
            height="336"
            src="https://www.youtube.com/embed/XidECrzcp28?controls=0&disablekb=1&fs=0&modestbranding=1&color=white"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Modal>
    <a
      :href="`https://instagram.com/${this.currentUser.blogger_profile.instaname}`"
      target="_blank"
      class="hidden"
      id="linkToInst"
    ></a>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  props: {
    show: Boolean,
  },
  components: {
    Modal,
  },
  computed: {
    ...mapState(['currentUser']),
    link() {
      return `${window.location.origin}/#/mutualpr/from_stories/${this.currentUser.id}?access=${this.currentUser.blogger_profile.da_token}`;
    },
  },
  data() {
    return {
      exampleMode: true,
      linkIsReady: false,
      linkToPromo: '',
    };
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    async copyAndOpen() {
      document.getElementById('linkToInst').click();
    },
  },
  async mounted() {
    this.linkIsReady = true;
  },
};
</script>

<style scoped>
.btn--orange {
  max-width: none !important;
}
</style>
