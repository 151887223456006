<template>
  <div class="offer--grid">
    <div class="offer__item" v-if="false">
      <div class="offer__content">
        <h3 class="offer__title">Не нашли подходящий аккаунт для ВП?</h3>
        <p class="offer__text">
          У вас есть возможность обновить выдачу и добавить 4 новых аккаунта.
        </p>
      </div>
      <div class="pr--add-new js-btnPopup" data-popup="popupNewService">
        <span class="pr--add-new-btn">Добавить 4 аккаунта</span>
        <span class="pr--add-new-free">от 39 ₽</span>
      </div>
    </div>
    <div class="offer__item">
      <h5 class="statistic-update__title">Создай карточку блогера и получи бонусы:</h5>
      <ul class="popup-new-prem__list">
        <li class="popup-new-prem__item">Плюс 3 дня сервиса ВП на завтра бесплатно</li>
        <li class="popup-new-prem__item">Плюс 1 размещение в чате Инсталогия и Easyprbot</li>
        <li class="popup-new-prem__item">Вашу карточку увидят потенциальные клиенты на сайте</li>
        <li class="popup-new-prem__item">
          Готовую карточку со статистикой, которую можно отправлять любым рекламодателям в директ
        </li>
        <li class="popup-new-prem__item">Чек-лист «Как анализировать блогера перед рекламой».</li>
      </ul>
      <router-link to="fillstat" class="btn-fill_stat btn--orange js-btnPopup"
        >Обновить статистику</router-link
      >
    </div>
    <div class="offer__item offer__item--light-bg" v-if="currentSearch">
      <p v-if="!searchCompleted" class="offer__text">
        Система в процессе набора групп, зайдите в
        {{ `${('0' + String(new Date().getHours() + 1)).slice(-2)}:00` }} по Москве, чтобы увидеть
        больше партнеров.
      </p>
      <br />
      <p class="offer__text">
        {{ searchCompleted ? 'Вы' : 'Также вы' }} можете найти партнеров на ВП у себя в сторис,
        разместив об этом объявление. Нажмите на кнопку ниже.
      </p>
      <b-button
        variant="outline-default"
        @click="() => $emit('show-vp-stories')"
        class="btn--search-insta js-btnPopup"
        data-popup="popup-search-insta"
      >
        <svg class="icon-search btn--search-insta__icon">
          <use xlink:href="static/images/sprite.svg#search"></use>
        </svg>
        Поиск ВП у меня в сторис
      </b-button>
    </div>
    <DefaultOffer
      v-if="showTgOfferProp"
      text="Не пропусти ВП, подпишись на уведомления в телеграме"
    >
      <b-button variant="outline-default" @click="hideTg" class="btn--border tg__button"
        >Скрыть</b-button
      >
      <b-button variant="primary" class="btn--blue tg__button" @click="tgSubscribe"
        >Подписаться</b-button
      >
    </DefaultOffer>
  </div>
</template>

<script>
import { getJoinLinks } from '@main/api/notify';
import { mapState } from 'vuex';
import DefaultOffer from '@mp/components/offers/DefaultOffer.vue';

export default {
  components: {
    DefaultOffer,
  },
  data: () => ({
    showTgOffer: true,
  }),
  computed: {
    ...mapState('mp_search', ['currentSearch']),
    showTgOfferProp() {
      return !localStorage.getItem('hide-tg-offer') && this.showTgOffer;
    },
    searchCompleted() {
      return this.currentSearch.results.length >= 10;
    },
  },
  methods: {
    async tgSubscribe() {
      const links = await getJoinLinks();
      window.location = links.tg;
    },
    hideTg() {
      this.showTgOffer = false;
      localStorage.setItem('hide-tg-offer', true);
    },
  },
};
</script>

<style lang="css" scoped>
.btn-fill_stat {
  text-align: center !important;
  cursor: pointer;
}
.btn--orange {
  max-width: none !important;
  padding: 8.5px 15px;
}
.tg__button {
  text-align: center;
  width: 120px;
  cursor: pointer;
}
.statistic-update__title {
  margin-bottom: 13px;
  font-size: 19px;
  font-weight: 500;
}
.offer--flex {
  flex-wrap: wrap;
}
@media (max-width: 390px) {
  .btn-fill_stat {
    width: 100%;
    max-width: none;
  }
}
.offer--grid {
  margin-top: 70px !important;
  display: grid;
  grid-gap: 70px;
  grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
}
</style>
