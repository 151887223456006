<template>
  <div class="pr-active">
    <div class="" v-if="!isMobile && hasPr">
      <div class="pr-active__content has-pr">
        <h1 class="pr-prem-out__title">ВП на завтра</h1>
        <Activation @show-requests="() => (showRequests = true)" :newRequests="requests.length" />
        <div class="pr-events pr-events__line">
          <TodayMutualPR :mutualPrs="mutalPrToday" />
          <PastMutualPR :mutualPrs="mutalPrPast" />
        </div>
      </div>
    </div>
    <div class="" v-else>
      <div class="pr-events pr-events--mgbottom" v-if="hasPr">
        <TodayMutualPR :mutualPrs="mutalPrToday" />
        <PastMutualPR :mutualPrs="mutalPrPast" />
      </div>
      <div class="pr__top">
        <div class="pr-prem-out__title-wrap">
          <h1 class="pr-prem-out__title">ВП на завтра</h1>
          <span class="to-desktop">Поиск ВП отключен</span>
        </div>
      </div>
      <div style="margin-top: 20px !important" class="pr-active__content">
        <Activation @show-requests="() => (showRequests = true)" :newRequests="requests.length" />
      </div>
    </div>
    <CardsModal
      :show="showRequests"
      @hide="() => (showRequests = false)"
      keyname="actRequests"
      cardMode="w"
      :objs="requests"
      title="Новые заявки"
    />
  </div>
</template>

<script>
import CardsModal from '@main/components/reusable/modals/CardsModal.vue';
import { mapState, mapActions } from 'vuex';
import PastMutualPR from '../SearchView/groups/PastMutualPR.vue';
import TodayMutualPR from '../SearchView/groups/TodayMutualPR.vue';
import Activation from './Activation.vue';

export default {
  components: {
    Activation,
    PastMutualPR,
    TodayMutualPR,
    CardsModal,
  },
  data: () => ({
    showRequests: false,
  }),
  computed: {
    ...mapState(['currentUser', 'isMobile']),
    ...mapState('mp_search', ['mutalPrToday', 'mutalPrPast', 'mutalPrNew']),
    hasPr() {
      return this.mutalPrToday.length > 0 || this.mutalPrPast.length > 0;
    },
    requests() {
      const prs = this.mutalPrNew.filter((val) => val.status === 1);
      const accs = prs.map((i) => {
        if (i.customer.id === this.currentUser.id) {
          return i.executor;
        }
        return i.customer;
      });
      return accs;
    },
  },
};
</script>

<style>
.has-pr {
  width: 100% !important;
  max-width: 1130px !important;
}
.pr-events__line {
  border-top: 1px solid lightgray;
  padding-top: 20px;
}
</style>
