<template>
  <div>
    <SearchView />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import SearchView from '../search/SearchView.vue';
import ShowCase from './ShowCase/ShowCase';

export default {
  name: 'home',
  components: { SearchView },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('mutualpr', ['authenticated']),
    ...mapGetters('mutualpr', ['regComplete']),
  },
};
</script>
