<template>
  <div class="offer__item">
    <p class="offer__text">{{ text }}</p>
    <div class="button__flex">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
};
</script>

<style lang="css" scoped>
.button__flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.button__flex > div {
  margin: 0 10px;
  width: 100%;
}
@media (max-width: 350px) {
  .button__flex {
    flex-direction: column;
  }
  .button__flex > div {
    margin: 0px !important;
    margin-top: 10px !important;
  }
}
</style>
